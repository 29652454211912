body {
    font-family: 'Rubik';
    background-image: url('../public/img/bg-home.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    /* darken the background image a bit */
    background-color: rgba(120, 120, 120, 0.05);
    background-blend-mode: color;

    --rounded-btn: 0px;
}

strong {
    font-weight: 600;
    letter-spacing: .6px;
}