@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1, h2, h3, h4 {
  font-weight: 500;
  letter-spacing: 0;
  color: #484848
}

h1 {
  font-size: 32px;
  line-height: 39px;
}

h2 {
  font-size: 18px;
  line-height: 22px;
}

h3 {
  font-size: 16px;
  line-height: 20px;
}

h4 {
  font-size: 14px;
  line-height: 19px;
}

h5 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0;
}

h6 {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

p, a, span, li {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
}
.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  -webkit-border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box
}
.sweet-alert .icon.error {
  border-color: #F27474
}
.sweet-alert .icon.error .x-mark {
  position: relative;
  display: block
}
.sweet-alert .icon.error .line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F27474;
  display: block;
  top: 37px;
  border-radius: 2px
}
.sweet-alert .icon.error .line.left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px
}
.sweet-alert .icon.error .line.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px
}
@-webkit-keyframes animateErrorIcon {
  0% {
      transform: rotateX(100deg);
      -webkit-transform: rotateX(100deg);
      opacity: 0
  }
  100% {
      transform: rotateX(0deg);
      -webkit-transform: rotateX(0deg);
      opacity: 1
  }
}
@keyframes animateErrorIcon {
  0% {
      transform: rotateX(100deg);
      -webkit-transform: rotateX(100deg);
      opacity: 0
  }
  100% {
      transform: rotateX(0deg);
      -webkit-transform: rotateX(0deg);
      opacity: 1
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon .5s;
  animation: animateErrorIcon .5s
}
@-webkit-keyframes animateXMark {
  0%, 50% {
      transform: scale(.4);
      -webkit-transform: scale(.4);
      margin-top: 26px;
      opacity: 0
  }
  80% {
      transform: scale(1.15);
      -webkit-transform: scale(1.15);
      margin-top: -6px
  }
  100% {
      transform: scale(1);
      -webkit-transform: scale(1);
      margin-top: 0;
      opacity: 1
  }
}
@keyframes animateXMark {
  0%, 50% {
      transform: scale(.4);
      -webkit-transform: scale(.4);
      margin-top: 26px;
      opacity: 0
  }
  80% {
      transform: scale(1.15);
      -webkit-transform: scale(1.15);
      margin-top: -6px
  }
  100% {
      transform: scale(1);
      -webkit-transform: scale(1);
      margin-top: 0;
      opacity: 1
  }
}
.animateXMark {
  -webkit-animation: animateXMark .5s;
  animation: animateXMark .5s
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #78BE20;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #78BE20;
    fill: #fff;
    opacity: 1;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}